<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Edit Product
        <div class="card-header-actions">
          <a
            class="card-header-action"
            href="jewelry-list"
            rel="noreferrer noopener"
          >
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row>
          <b-col sm="12">
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="upload" novalidate>
              <b-form-group
                id="firstNameInputGroup1"
                label="Jewelry Name"
                label-for="firstname"
              >
                <b-form-input
                  id="name"
                  type="text"
                  v-model.lazy.trim="$v.form.name.$model"
                  :state="chkState('name')"
                  aria-describedby="nameFeedback"
                  placeholder="Name"
                  autofocus
                />
                <b-form-invalid-feedback
                  id="nameFeedback"
                  v-for="(error, index) in errors.form.name"
                  :key="index"
                >
                  - {{ error }} <br />
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="descriptionInputGroup2"
                label="Description"
                label-for="description"
              >
                <quill-editor
                  id="description"
                  aria-describedby="descriptionFeedback"
                  v-model.lazy.trim="$v.form.description.$model"
                  :state="chkState('description')"
                  autocomplete="family-name"
                >
                </quill-editor>
                <div v-if="this.form.description == ''">
                  <p
                    id="descriptionFeedback"
                    v-for="(error, index) in errors.form.description"
                    :key="index"
                  >
                    - {{ error }} <br />
                  </p>
                </div>
              </b-form-group>
              <b-form-group
                id="typeInputGroup1"
                label="Category"
                label-for="category"
              >
                <b-form-select
                  id="category"
                  :plain="true"
                  v-model.lazy.trim="$v.form.category.$model"
                  :state="chkState('category')"
                  aria-describedby="categoryFeedback"
                  autofocus
                >
                  <option value="">Choose a Category</option>
                  <option
                    v-for="(category, key) in categories"
                    :key="key"
                    :value="category.id"
                  >
                    - {{ category.name }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback
                  id="categoryFeedback"
                  v-for="(error, index) in errors.form.category"
                  :key="index"
                >
                  - {{ error }} <br />
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="brand" label="SKU Source" label-for="brand">
                <b-form-select
                  id="brand"
                  :plain="true"
                  v-model.lazy.trim="$v.form.brand.$model"
                  :state="chkState('brand')"
                  aria-describedby="brandFeedback"
                  autofocus
                >
                  <option value="">Choose a Brand</option>
                  <option
                    v-for="(brand, key) in brands"
                    :key="key"
                    :value="brand.id"
                  >
                    - {{ brand.name }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback
                  id="brandFeedback"
                  v-for="(error, index) in errors.form.brand"
                  :key="index"
                >
                  - {{ error }} <br />
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="pure"
                label="Kemurnian Produk SKU"
                label-for="pure"
              >
                <b-form-input
                  id="pure"
                  type="number"
                  v-model.trim="$v.form.pure.$model"
                  :state="chkState('pure')"
                  aria-describedby="pureFeedback"
                  placeholder="pure"
                  autocomplete="pure"
                />
                <b-form-invalid-feedback id="pureFeedback">
                  <span v-if="!$v.form.pure.decimal"
                    >- Kemurnian Produk SKU harus berupa angka valid.</span
                  >
                  <span v-if="!$v.form.pure.maxValue"
                    >- Kemurnian Produk SKU tidak boleh melebihi 999.</span
                  >
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="finishing"
                label="Finishing"
                label-for="finishing"
              >
                <b-form-input
                  id="finishing"
                  type="text"
                  v-model.trim="$v.form.finishing.$model"
                  :state="chkState('finishing')"
                  aria-describedby="finishingFeedback"
                  placeholder="finishing"
                  autofocus
                />
                <b-form-invalid-feedback
                  id="finishingFeedback"
                  v-for="(error, index) in errors.form.finishing"
                  :key="index"
                >
                  - {{ error }} <br />
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="size" label="Size" label-for="size">
                <b-form-input
                  id="size"
                  type="text"
                  v-model="form.size"
                  aria-describedby="sizeFeedback"
                  placeholder="size"
                  autofocus
                />
              </b-form-group>
              <b-form-group
                id="passInputGroup5"
                label="weight"
                label-for="weight"
              >
                <b-form-input
                  id="weight"
                  type="text"
                  v-model.trim="$v.form.weight.$model"
                  :state="chkState('weight')"
                  aria-describedby="weightFeedback"
                  placeholder="weight"
                  autocomplete="weight"
                />
                <b-form-invalid-feedback id="weightFeedback">
                  <span v-if="!$v.form.weight.required"
                    >- Weight tidak boleh kosong.</span
                  >
                  <span v-if="!$v.form.weight.decimal"
                    >- Weight harus berupa angka valid.</span
                  >
                  <span v-if="!$v.form.weight.maxValue"
                    >- Weight tidak boleh melebihi 100.</span
                  >
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="emailInputGroup4"
                label="Base Price"
                label-for="email"
              >
                <b-form-input
                  id="price"
                  type="number"
                  v-model.trim="$v.form.price.$model"
                  :state="chkState('price')"
                  aria-describedby="priceFeedback"
                  placeholder="Price Product"
                  autocomplete="price"
                />
                <b-form-invalid-feedback
                  id="priceFeedback"
                  v-for="(error, index) in errors.form.price"
                  :key="index"
                >
                  - {{ error }} <br />
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="promotion_type"
                label="Discount Type"
                v-if="form.brand === 2"
                label-for="promotion_type"
              >
                <b-form-select
                  id="promotion_type"
                  v-model="form.promotion_type"
                  :plain="true"
                  :options="[
                    { value: 'no_promotion', text: 'No Discount' },
                    { value: 'fixed', text: 'Fixed Price' },
                    { value: 'percent', text: 'Percentage' },
                  ]"
                >
                </b-form-select>
              </b-form-group>
              <b-form-group
                id="promotion_amount"
                label="Discount"
                v-if="form.brand === 2"
                label-for="promotion_amount"
              >
                <b-form-input
                  id="promotion_amount"
                  type="number"
                  v-model.lazy.trim="$v.form.promotion_amount.$model"
                  :state="chkState('promotion_amount')"
                  :disabled="form.promotion_type === 'no_promotion' && this.form.price === '' ? true : false"
                  aria-describedby="promotionAmountFeedback"
                  placeholder="0"
                  @keypress="setCalculate"
                  @keydown="setCalculate"
                  @keyup="setCalculate"
                  autocomplete="given-name"
                  autofocus
                />
                <b-form-invalid-feedback id="promotionAmountFeedback">
                  <span v-if="!$v.form.promotion_amount.decimal"
                    >- Discount tidak boleh kurang dari 0!</span
                  >
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="promotion_price"
                label="Promotion Price"
                v-if="form.brand === 2"
                label-for="promotion_price"
              >
                <b-form-input
                  id="price"
                  type="number"
                  disabled
                  v-model="form.promotion_price"
                  placeholder="Promotion Price"
                  autocomplete="price"
                />
              </b-form-group>
              <b-form-group
                id="emailInputGroup4"
                label="Stock"
                label-for="stock"
              >
                <b-form-input
                  id="stock"
                  type="number"
                  v-model.trim="$v.form.stock.$model"
                  :state="chkState('stock')"
                  aria-describedby="stockFeedback"
                  placeholder="0"
                  autocomplete="stock"
                />
                <b-form-invalid-feedback
                  id="stockFeedback"
                  v-for="(error, index) in errors.form.stock"
                  :key="index"
                >
                  - {{ error }} <br />
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Limited Edition" label-for="limited">
                <b-form-checkbox-group
                  id="limited"
                  name="InlineCheckboxes"
                  :plain="true"
                  v-model="status"
                >
                  <b-form-checkbox :plain="true" :value="true"
                    >Active</b-form-checkbox
                  >
                </b-form-checkbox-group>
              </b-form-group>
              <b-row
                v-for="(group, index) in galery"
                :key="index"
                class="m-3 text-center"
              >
                <b-col sm="12" md="2" lg="2">
                  <b-form-checkbox v-model="group.status" value="true">
                    Active On Mobile
                  </b-form-checkbox>
                </b-col>
                <b-col sm="12" md="3" lg="3">
                  <b-form-file
                    v-model="group.image"
                    class="mb-2"
                    accept=".jpeg, .png"
                  ></b-form-file>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <img
                    style="max-width: 100%; height: auto"
                    :src="group.image_path"
                    :alt="group.name"
                    width="100px"
                    height="100px"
                  />
                </b-col>
                <b-col sm="12" md="3" lg="3">
                  <b-form-input
                    id="name"
                    class="mb-2"
                    type="text"
                    v-model.trim="group.name"
                    placeholder="name"
                    autocomplete="name"
                  />
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-button-group>
                    <b-button @click="group.image = []">Reset Image</b-button>
                    <b-button variant="danger" @click="removeRow(index)">
                      delete
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
              <b-row class="text-center">
                <b-col>
                  <b-button variant="primary" type="button" @click="addRow"
                    >Add More</b-button
                  >
                </b-col>
              </b-row>
              <b-button type="submit" variant="primary"> Submit </b-button>
            </b-form>
            <br />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, numeric, helpers, maxValue } from "vuelidate/lib/validators";

const decimal = helpers.regex(
  "decimal",
  /^[+]?([0-9]+(?:[.][0-9]*)?|[.][0-9]+)(?:[eE][+-]?[0-9]+)?$/
);

export default {
  name: "ValidationForms",
  data() {
    return {
      form: {
        name: "",
        short_description: "",
        description: "",
        weight: "",
        category: "",
        price: "",
        stock: "",
        brand: "",
        finishing: "",
        size: "",
        pure: "",
        promotion_type: 'no_promotion',
        promotion_amount: '',
        promotion_price: ''
      },
      oldImages: [],
      status: "",
      baseUrlPath: process.env.VUE_APP_SECRET,
      isDragging: false,
      dragCount: 0,
      index: 0,
      files: [],
      images: [],
      category: "",
      categories: [],
      galery: [
        {
          status: false,
          image: "",
          name: "",
          image_path: "",
          origin_path: "",
        },
      ],
      brands: [],
      errors: {
        message: [],
        status: [],
        code: "",
        headers: [],
        form: {
          name: [],
          sku: [],
          short_description: [],
          category: [],
          description: [],
          weight: [],
          price: [],
          stock: [],
          images: [],
          brand: [],
          finishing: [],
          size: [],
        },
      },
      isLoading: false,
    };
  },
  computed: {
    formStr() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
    invCheck() {
      return "You must accept before submitting";
    },
  },
  created() {
    this.$http
      .put(`product-data/` + this.$route.params.id)
      .then((result) => {
        this.form.name = result.data.products.name;
        this.form.short_description = result.data.products.short_description;
        this.form.description = result.data.products.description;
        this.form.weight = result.data.details.weight;
        this.form.price = result.data.details.price;
        this.form.stock = result.data.details.stock;
        this.form.category = result.data.products.category_id;
        this.oldImages = result.data.products.product_images_id;
        this.form.brand = result.data.products.brand_id;
        this.galery = result.data.details.product_images;
        this.form.finishing = result.data.details.finishing;
        this.form.size = result.data.details.size;
        this.form.pure = result.data.details.purity_label;
        this.form.promotion_type = result.data.products.promotion_type === null ? 'no_promotion' : result.data.products.promotion_type
        this.form.promotion_amount = result.data.products.promotion_type === null ? 'no_promotion' : result.data.products.promotion_amount
        this.form.promotion_price = result.data.products.promotion_type === null ? 'no_promotion' : result.data.products.promotion_price
        this.status = result.data.products.is_limited;
      })
      .catch((error) => {
        if (error.response) {
          this.errors.code = error.response.data.meta.code;
          this.errors.status = error.response.status;
          this.errors.headers = error.response.headers;
        }
      });

    this.$http.get(`brand-data`).then((result) => {
      this.brands = result.data.brands;
    });
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
      },
      description: {
        required,
      },
      weight: {
        required,
        decimal,
        maxValue: maxValue(100),
      },
      pure: {
        decimal,
        maxValue: maxValue(999),
      },
      price: {
        required,
        numeric,
      },
      finishing: {
        required,
      },
      stock: {
        required,
        numeric,
      },
      category: {
        required,
      },
      brand: {
        required,
      },
      promotion_amount: {
        decimal,
      },
    },
    category: {
      required,
    },
    images: {
      required,
    },
  },
  methods: {
    setCalculate() {
      if (this.form.promotion_type === 'fixed') {
        this.form.promotion_price = this.form.price - this.form.promotion_amount
      } else {
        this.form.promotion_price = this.form.price - (this.form.price * (this.form.promotion_amount / 100))
      }

      if (this.form.promotion_price < 0) {
        return this.$swal.fire(
          'Failed!',
          'Nilai Discount tidak valid.',
          'error'
        ).then(() => {
          this.form.promotion_amount = ''
          this.form.promotion_price = ''
        })
      }
    },
    addRow() {
      let raw = { status: false, image: "", name: "" };
      this.galery.push(...[raw]);
    },
    removeRow(index) {
      this.galery.splice(index, 1);
    },
    deleteRow(image) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You will not be able to recover this Image!",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, keep it",
        })
        .then((result) => {
          if (result.value) {
            this.$http.delete(`jewelry-images/` + image.id).then(() => {
              this.$swal
                .fire("Deleted!", "Image has been deleted.", "success")
                .then(() => {
                  location.reload();
                });
            });
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelled",
              "Image was successfully canceled for deletion!",
              "error"
            );
          }
        });
    },
    download(file, name) {
      var link = window.document.createElement("a");
      link.download = name + ".jpg";
      link.target = "_blank";

      // Construct the URI
      link.href = file;
      document.body.appendChild(link);
      link.click();

      // Cleanup the DOM
      document.body.removeChild(link);
      // delete link;
    },
    removeImage(i) {
      var arrayImages = this.images;
      var array = this.files;
      var index = arrayImages.indexOf(arrayImages[i]);
      var secondIndex = array.indexOf(array[i]);
      if (index >= 0) {
        arrayImages.splice(index, 1);
      }

      if (secondIndex >= 0) {
        array.splice(secondIndex, 1);
      }
    },
    OnDragEnter(e) {
      e.preventDefault();

      this.dragCount++;
      this.isDragging = true;
      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) this.isDragging = false;
    },
    onInputChange(e) {
      const files = e.target.files;
      Array.from(files).forEach((file) => this.addImage(file));
    },
    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const files = e.dataTransfer.files;
      Array.from(files).forEach((file) => this.addImage(file));
    },
    addImage(file) {
      if (!file.type.match("image.*")) {
        this.$toastr.e(`${file.name} is not an image`);
        return;
      }
      this.files.push(file);
      const reader = new FileReader();
      reader.onload = (e) => this.images.push(e.target.result);
      reader.readAsDataURL(file);
    },
    getFileSize(size) {
      const fSExt = ["Bytes", "KB", "MB", "GB"];
      let i = 0;

      while (size > 900) {
        size /= 1024;
        i++;
      }
      return `${Math.round(size * 100) / 100} ${fSExt[i]}`;
    },
    upload() {
      this.validate();
      this.isLoading = true;
      const formData = new FormData();
      formData.append("name", this.form.name);
      formData.append("category_id", this.form.category);
      formData.append("description", this.form.description);
      formData.append("weight", this.form.weight);
      formData.append("price", this.form.price);
      formData.append("stock", this.form.stock);
      formData.append("brand", this.form.brand);
      formData.append("finishing", this.form.finishing);
      formData.append("size", this.form.size);
      formData.append("pure", this.form.pure);
      formData.append("promotion_type", this.form.promotion_type === 'no_promotion' || this.form.brand === 1 ? '' : this.form.promotion_type);
      formData.append("promotion_amount", this.form.promotion_type === 'no_promotion' || this.form.brand === 1 ? '' : this.form.promotion_amount);
      formData.append("promotion_price", this.form.promotion_type === 'no_promotion' || this.form.brand === 1 ? '' : this.form.promotion_price);
      formData.append("is_limited", this.status === true ? 1 : 0);
      this.galery.map((v, i) => {
        formData.append("galery[" + i + "][status]", v.status);
        formData.append("galery[" + i + "][image_path]", v.file_path);
        formData.append(
          "galery[" + i + "][image]",
          typeof v.image === "undefined" ? "" : v.image
        );
        formData.append("galery[" + i + "][name]", v.name);
      });

      this.$http
        .post(`product-update/` + this.$route.params.id, formData)
        .then(() => {
          this.isLoading = false;
          this.$router.push({ name: "Jewelry List" });
          this.$toasted.success("Jewelry product successfully updated!");
        })
        .catch((error) => {
          if (error.response) {
            this.isLoading = false;
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
            if (this.errors.status == 422) {
              this.$toasted.error(
                "Please fill out the form that must be required"
              );
              this.errors.message = error.response.data.meta.message;
              this.errors.form.name = this.errors.message.name;
              this.errors.form.category = this.errors.message.category_id;
              this.errors.form.short_description =
                this.errors.message.short_description;
              this.errors.form.description = this.errors.message.description;
              this.errors.form.weight = this.errors.message.weight;
              this.errors.form.price = this.errors.message.price;
              this.errors.form.stock = this.errors.message.stock;
              this.errors.form.finishing = this.errors.message.finishing;
              this.errors.form.size = this.errors.message.size;
              this.errors.form.image = this.errors.message.images;
            } else if (this.errors.status == 400) {
              this.$swal.fire(
                "Failed!",
                error.response.data.meta.message,
                "error"
              );
            }
          }
        });
      //   })
      // }
    },
    chkState(val) {
      const field = this.$v.form[val];
      return !field.$dirty || !field.$invalid;
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
    loadCategory() {
      this.$http
        .get(`category-data`, {
          params: {
            categories: this.category,
          },
        })
        .then((result) => {
          this.categories = result.data.categories;
        })
        .catch((error) => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.headers = error.response.headers;
          }
        });
    },
  },
  mounted() {
    this.loadCategory();
  },
};
</script>
<style>
#descriptionFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}

#imageFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}
.invalid-feedback {
  font-size: 100%;
  color: #f86c6b;
}

.img-wrapper {
  position: relative;
  width: 50%;
}
.recentImage {
  width: 100%;
  height: auto;
}
.close {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.details {
  transition: 0.5s ease;
}
.img-wrapper:hover .image {
  opacity: 0.3;
}
.img-wrapper:hover .details {
  opacity: 0.3;
  transition: 0.5s ease;
}
.img-wrapper:hover .close {
  opacity: 1;
}
.text {
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}
.image {
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.btn-close {
  background-color: DodgerBlue;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 15%;
}

/* Darker background on mouse-over */
.btn-close:hover {
  background-color: RoyalBlue;
}

i.fa.fa-close {
  margin-top: -5px;
}
</style>
<style lang="scss" scoped>
.uploader {
  width: 100%;
  background: #2196f3;
  color: #fff;
  padding: 40px 15px;
  text-align: center;
  border-radius: 10px;
  border: 3px dashed #fff;
  font-size: 20px;
  position: relative;

  &.dragging {
    background: #fff;
    color: #2196f3;
    border: 3px dashed #2196f3;

    .file-input label {
      background: #2196f3;
      color: #fff;
    }
  }

  i {
    font-size: 85px;
  }

  .file-input {
    width: 50%;
    margin: auto;
    height: 68px;
    position: relative;

    .file,
    .input-image {
      background: #fff;
      color: #2196f3;
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      padding: 10px;
      border-radius: 4px;
      margin-top: 7px;
      cursor: pointer;
    }

    .input-image {
      opacity: 0;
      z-index: -2;
    }
  }

  .images-preview {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    .img-wrapper {
      width: 160px;
      display: flex;
      flex-direction: column;
      margin: 10px;
      height: 150px;
      justify-content: space-between;
      background: #fff;
      box-shadow: 5px 5px 20px #3e3737;

      .image {
        max-height: 105px;
      }

      .olderImage {
        max-height: 150px;
      }
    }

    .details {
      font-size: 12px;
      background: #1095ff;
      color: #fff;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      align-items: self-start;
      padding: 5px 6px;

      .name {
        overflow: hidden;
        height: 18px;
      }
    }
  }

  .upload-control {
    position: absolute;
    width: 100%;
    background: #fff;
    top: 0;
    left: 0;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding: 10px;
    padding-bottom: 4px;
    text-align: right;

    .upload,
    .label-upload {
      background: #2196f3;
      border: 2px solid #83a9f4;
      border-radius: 3px;
      color: #fff;
      font-size: 15px;
      cursor: pointer;
    }

    .label-upload {
      padding: 2px 5px;
      margin-right: 16px;
    }
  }
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
